import * as Sentry from '@sentry/react';
import config from '../config';

const isLocal = process.env.NODE_ENV === 'development';

export function initSentry() {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.clientEnv,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        id: 'custom-feedback-btn',
        tags: [],
        showBranding: false,
        showName: true,
        showEmail: false,
        enableScreenshot: true,
        isNameRequired: true,
        isEmailRequired: false,
        useSentryUser: { name: 'name' },
        triggerLabel: 'Give Feedback',
        triggerAriaLabel: 'Give Feedback',
        formTitle: 'Give Feedback',
        submitButtonLabel: 'Send',
        cancelButtonLabel: 'Cancel',
        confirmButtonLabel: 'Confirm',
        addScreenshotButtonLabel: 'Add a screenshot',
        removeScreenshotButtonLabel: 'Remove screenshot',
        nameLabel: 'Full Name',
        namePlaceholder: 'Your Full Name',
        isRequiredLabel: '(required)',
        messageLabel: 'Description',
        messagePlaceholder: "What's the feedback?",
        successMessageText: 'Thank you for your feedback!',
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  console.log('Sentry initialized', {dns: config.sentryDsn});
}

export function logError(error, errorInfo = null) {
  if (isLocal) {
    return;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

export function onError(error) {
  let errorInfo = {};
  let message = error ? error.toString() : 'unknown error!';

  // Auth errors
  if (!(error instanceof Error) && error && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error && error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  alert(message);
}
