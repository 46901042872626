export const RESPONSE_TYPES = {
  STT: 'stt',
  CHITCHAT: 'CHITCHAT',
  FALLBACK: 'FALLBACK',
  AGE_QUESTION: 'AGE_QUESTION',
  GENDER_QUESTION: 'GENDER_QUESTION',
  OPTION_QUESTION: 'OPTION_QUESTION',
  PREGNANCY_QUESTION: 'PREGNANCY_QUESTION',
  MENOPAUSE_QUESTION: 'MENOPAUSE_QUESTION',
  SINGLE_SYMPTOM_QUESTION: 'SINGLE_SYMPTOM_QUESTION',
  MULTIPLE_SYMPTOM_QUESTION: 'MULTIPLE_SYMPTOM_QUESTION',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  OPEN_QUESTION: 'OPEN_QUESTION',
  DIAGNOSIS_RESULT: 'DIAGNOSIS_RESULT',
};

export const ZAIDOC_DASHBOARD_HOSTNAME = {
  dev: 'dev.zaidoc.ai',
  qa: 'qa.zaidoc.ai',
  release: 'release.zaidoc.ai',
  kenya: 'nhc.ken.zaidoc.ai',
  zambia: 'ams.zmb.zaidoc.ai',
  'nga-acc': 'acc.nga.zaidoc.ai',
  'ken-aaf': 'aaf.ken.zaidoc.ai',
  'ken-amc': 'amc.ken.zaidoc.ai',
  'ken-ehc': 'ehc.ken.zaidoc.ai',
  'ken-kah': 'kah.ken.zaidoc.ai',
  'ken-nyh': 'nyh.ken.zaidoc.ai',
  'ken-m4h': 'm4h.ken.zaidoc.ai',
  'ken-khc': 'khc.ken.zaidoc.ai',
  'ken-ktr': 'ktr.ken.zaidoc.ai',
  nigeria: 'acc.nga.zaidoc.ai',
  'nga-dmh': 'dmh.nga.zaidoc.ai',
  'nga-hmh': 'hmh.nga.zaidoc.ai',
  'nga-hsh': 'hsh.nga.zaidoc.ai',
  'nga-rds': 'rds.nga.zaidoc.ai',
};

export const REPORT_GENERATION_STATUS = {
  ERROR: 'error',
  PENDING: 'generating',
  COMPLETED: 'completed',
};

export const HOSTNAME = {
  PROD: 'chat.zaidoc.ai',
  DEV: 'chat.dev.zaidoc.ai',
  STAGE: 'chat.stage.zaidoc.ai',
  STREAM: 'dh.zaidoc.ai',
  LOCAL: 'localhost',
};

export const CLIENT_ENVS = {
  RELEASE: 'release',
  DEVELOPMENT: 'development',
  STAGE: 'stage',
  STREAM: 'stream',
  LOCAL: 'local',
};
