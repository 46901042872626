import configDev from './config-dev';
import configProd from './config-prod';
import configStaging from './config-stage';
import { CLIENT_ENVS, HOSTNAME } from '@constants';
import translations from '@constants/translations.json';

const hostName = window.location.hostname;

export const getClientEnv = (hostName) => {
  switch (hostName) {
    case HOSTNAME.PROD:
      return CLIENT_ENVS.RELEASE;

    case HOSTNAME.DEV:
      return CLIENT_ENVS.DEVELOPMENT;

    case HOSTNAME.STAGE:
      return CLIENT_ENVS.STAGE;

    case HOSTNAME.STREAM:
      return CLIENT_ENVS.STREAM;

    default:
      return CLIENT_ENVS.LOCAL;
  }
};

export const PLATFORMS = {
  STREAMING: 'streaming',
  CHAT: 'chat',
};

// envs
const CHAT_TYPE = process.env.REACT_APP_PLATFORM;
const SERVER_URL_DEV = process.env.REACT_APP_SERVER_URL_DEV;
const SERVER_URL_PROD = process.env.REACT_APP_SERVER_URL_PROD;
const SERVER_URL_STAGE = process.env.REACT_APP_SERVER_URL_STAGE;
const SERVER_URL_LOCAL = process.env.REACT_APP_SERVER_URL_LOCAL;
const STREAM_URL = process.env.REACT_APP_STREAM_URL;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

let config = {
  platform: CHAT_TYPE || PLATFORMS.CHAT,
  PLATFORMS,
  isChat: CHAT_TYPE === PLATFORMS.CHAT,
  isStream: CHAT_TYPE === PLATFORMS.STREAMING,
  clientEnv: getClientEnv(hostName),
  hostName,
  serverBaseDev: SERVER_URL_DEV,
  serverBaseProd: SERVER_URL_PROD,
  serverBaseStage: SERVER_URL_STAGE,
  serverBaseLocal: SERVER_URL_LOCAL,
  streamBase: STREAM_URL,
  sentryDsn: SENTRY_DSN,
  translations
};

if (process.env.REACT_APP_ENV === 'PRODUCTION') {
  config = { ...configProd, ...config };
} else if (process.env.REACT_APP_ENV === 'STAGING') {
  config = { ...configStaging, ...config };
} else {
  config = { ...configDev, ...config };
}

export default config;
